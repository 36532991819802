@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.link {
  text-decoration: none;
  text-decoration-color: $color-default !important;

  &:hover {
    text-decoration: underline;

    & img {
      transform: scale(1.02);
    }
  }
}

.container {
  background-color: $color-white;
  padding-bottom: 3.2rem;

  @include respond(tab-port) {
    margin-bottom: 3.2rem;
  }

  @include respond(mobile) {
    margin-bottom: 4rem;
  }

  &:hover {
    cursor: pointer;
  }
}

.imgWrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
  aspect-ratio: 4 / 3;

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: all 0.2s;
  }
}

.underImage {
  display: flex;
  margin-top: 2.4rem;
  flex-direction: column;
  gap: 0.8rem;
  color: $color-default;
  font-weight: 400;
}

.title {
  font-size: $text-xl;
  font-weight: 400;
  line-height: 2.4rem;
  letter-spacing: 0.1em;
}

.subtitle {
  font-weight: 400;
  line-height: 2.4rem;
  letter-spacing: 0.1em;
}

.priceWrapper {
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-size: $text-lg;
  line-height: 2.2rem;

  @media only screen and (max-width: 992px) {
  }

  @media only screen and (max-width: 950px) {
  }
}
