@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.text {
  margin-top: 0.8rem;
  font-weight: 400;
  line-height: 2.4rem;
  white-space: pre-wrap;

  & p {
    margin-bottom: 2rem;
  }

  @include respond(tablet) {
    margin-top: 1rem;
    font-size: $text-xl;
    line-height: 2.8rem;
  }
}

.container {
  padding-inline: 3.2rem;
  padding-block: 8rem;

  @include respond(tablet) {
    padding-block: 6rem;
  }

  @include respond(tab-port) {
    padding-top: 2rem;
    padding-bottom: 0;
  }
}
