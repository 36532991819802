@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.container {
  padding: 2rem 0;
  border-bottom: 1px solid $color-lighter-grey;
}

.button {
  font-family: 'neue-haas-grotesk-text', sans-serif;
  font-size: $text-xl;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  text-align: left;
  font-weight: 600;

  @include respond(tablet) {
    font-size: $text-lg;
  }
}

.buttonBefore {
  min-width: 2rem;
}

.content {
  padding-left: 2rem;
  padding-top: 2rem;
  line-height: 2.4rem;
}
