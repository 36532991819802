@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  padding: 0 4rem;
}

.sort {
  align-self: flex-end;
}

.productsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(1rem, auto);
  gap: 1.6rem;
  margin-top: 1.6rem;
  width: 100%;

  @include respond(tab-port) {
    grid-template-columns: 1fr;
    margin-top: 3.5rem;
  }
}

.paginationWrapper {
  align-self: flex-end;
}
