@import '@/styles/abstracts/mixins';

.wrapper {
  padding-inline: 3.2rem;
  max-width: 1600px;
  margin: 0 auto 16rem auto;

  @include respond(tablet) {
    margin-bottom: 8rem;
  }

  @include respond(mobile) {
    margin-bottom: 4rem;
  }
}

.video {
  width: 100%;
  height: auto;
}
