@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.heading {
  font-weight: 600;
  font-size: $text-3xl;

  @include respond(tablet) {
    font-size: $text-2xl;
  }
}
