@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.mainContent {
  width: 100%;
}

.productsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.6rem;
  margin-top: 1.6rem;
  width: 100%;

  @include respond(tab-port) {
    grid-template-columns: 1fr;
    margin-top: 3.5rem;
  }
}

.imgWrapper {
  width: 100%;
}

.img {
  object-fit: contain;
  width: 100% !important;
  height: auto !important;
}
