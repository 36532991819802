@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  padding-inline: 3.2rem;
}

.container {
  padding-bottom: 4.1rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  color: $color-default;

  @include respond(tablet) {
    padding-bottom: 3rem;
  }
}

.contentWrapper {
  padding-block: 10.5rem;
  padding-inline: 28.1rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  background-color: $color-background;

  @include respond(tab-land) {
    padding-block: 9.5rem;
    padding-inline: 15.1rem;
  }

  @include respond(tablet) {
    padding-block: 8.5rem;
    padding-inline: 5.1rem;
  }

  @include respond(mobile) {
    padding-inline: 4rem;
  }

  p,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: $text-xl;
  }
}
