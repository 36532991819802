@import '../../styles/abstracts/mixins';

.container {
  padding-inline: 3.2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;

  @include respond(mobile) {
    padding-inline: 2rem;
  }
}
