@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.containerWrapper {
  margin-bottom: 0;
  padding-top: 3.2rem;
  background-color: $color-background;

  @include respond(mobile) {
    padding: 4rem 0 0 0;
  }
}

.container {
  display: flex;
  gap: 1.6rem;

  @include respond(tablet) {
    flex-direction: column;
  }
}

.leftColumn {
  display: flex;
  flex-direction: column;
  flex: 68%;
  margin-bottom: 3.2rem;

  @include respond(tab-port) {
    margin: 0;
  }
}

.link {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: fit-content;
  text-decoration: none;
  color: $color-default;

  & p {
    line-height: 1.3rem;
    font-weight: 500;
  }
}

.error {
  color: $color-error;
  padding-top: 1.5rem;
}

.rightColumn {
  flex: 32%;
}

.backToCart {
  margin: 0;
  padding-top: 2.6rem;
  padding-bottom: 9.6rem;
  background-color: $color-light;

  @include respond(tab-port) {
    padding-top: 3rem;
    padding-bottom: 4.8rem;
  }
}

.deliveryContainer {
  background-color: $color-white;
  color: $color-default;
  display: flex;
  flex-direction: column;
  padding: 2.4rem 1.6rem;
  margin-bottom: 1.6rem;

  @include respond(tab-port) {
    padding: 1.5rem 3.75rem 3rem 3.75rem;
  }

  @include respond(tablet) {
    padding: 3rem 2.5rem;
  }
}

.deliveryTitle {
  font-weight: 500;
  line-height: 2.2rem;

  @include respond(mobile) {
    font-size: $text-xl;
    line-height: 2.75rem;
  }
}

.buttonWrapper {
  width: min-content;
  margin-top: 4rem;
}

.button {
  font-size: $text-base;
}

.checkbox {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 1.6rem;
  margin-top: 2.4rem;

  @include respond(mobile) {
    gap: 3rem;
    margin: 3.2rem 0 1.5rem 0;
  }
}
