@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.container {
  margin-top: 8rem;
  margin-bottom: 8rem;

  @include respond(mobile) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

.wrapper {
  margin: 0;
  display: flex;
  justify-content: flex-start;
  background-color: $color-background;
  padding: 4rem;

  @include respond(mobile) {
    padding: 2rem;
  }
}

.messageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.thanksWrapper {
  display: flex;
  gap: 1.6rem;
  align-items: center;

  & h2 {
    font-weight: 600;
    line-height: 2.6rem;
    font-size: $text-xl;

    @include respond(mobile) {
      font-size: $text-base;
    }
  }

  & img {
    width: 2.4rem;
    height: 2.4rem;

    @include respond(mobile) {
      width: 3rem;
      height: 3rem;
    }
  }

  @include respond(mobile) {
    gap: 2rem;
  }
}

.orderInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding-top: 2.4rem;
  margin-bottom: 6.4rem;

  & p {
    font-weight: 500;
    line-height: 2rem;

    @include respond(mobile) {
      font-size: $text-base;
      line-height: 2.5rem;
    }
  }

  @include respond(mobile) {
    gap: 1.5rem;
  }
}

.socialWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 2.4rem;
  margin-bottom: 6.4rem;
  gap: 1.6rem;

  & p {
    line-height: 1.92rem;
    font-weight: 500;
  }
}

.iconsWrapper {
  display: flex;
  gap: 0.72rem;

  & img {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.link {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  width: fit-content;
  text-decoration: none;
  color: $color-default;

  & p {
    line-height: 1.333rem;
    font-weight: 500;
  }
}

.orderDetails {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 6rem;

  & h3 {
    font-weight: 600;
    line-height: 2.6rem;
    font-size: $text-xl;
    margin-bottom: 0.5rem;
  }
}

.productsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.productList {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & li {
    list-style: none;
  }
}

.customerWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & h3 {
    font-weight: 600;
    line-height: 2.6rem;
    font-size: $text-xl;
  }
}

.customerInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
