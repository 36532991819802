@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.container {
  padding-inline: 3.2rem;
  padding-block: 8rem;

  @include respond(tablet) {
    padding-block: 6rem;
  }

  @include respond(tab-port) {
    padding-top: 2rem;
    padding-bottom: 0;
  }
}
