@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.linksWrapper {
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
}

.label {
  margin-bottom: 2.9rem;
  font-weight: 400;
  font-size: $text-xl;

  line-height: 2.4rem;
  letter-spacing: 0.1em;
  color: $color-background;
  text-transform: uppercase;
}

.link {
  font-weight: 450;
  font-size: $text-2xl;
  line-height: 2.7rem;
  color: $color-space-grey;
  text-decoration: none;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }

  @include respond(big-desktop) {
    font-size: $text-xl;
    line-height: 2.3rem;
  }

  @include respond(tablet) {
    font-size: $text-xl;
    line-height: 2.3rem;
  }
}
