@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.container {
  margin-top: 6rem;
}
.employeesContainer {
  margin-top: 6rem;
}

.employeeContainer {
  display: flex;
  gap: 4rem;
  justify-content: center;
  margin-top: 4rem;

  @include respond(tablet) {
    flex-direction: column;
  }
}

.imageContainer {
  position: relative;
  min-width: 40rem;
  height: 40rem;

  @include respond(tablet) {
    min-width: 30rem;
    height: 30rem;
  }
}

.descriptionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 30rem;

  @include respond(tablet) {
    max-width: 100%;
  }
}

.image {
  object-fit: contain;
}
