@import '@/styles/abstracts/mixins';
@import '@/styles/abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.sectionContainer {
  background-color: $color-background;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin-top: 2rem;
  padding: 2rem;

  @include respond(tablet) {
    flex-direction: column;
  }
}
